import {mapActions, mapGetters, mapMutations} from 'vuex';


export default {
  name: 'cookies',

  data() {
    return {
      payload: {
      },
    }
  },
  computed: {
  },
  components: {
  },
  created(){
  },
  methods: {
    ...mapMutations({
      changeShowCookiesPopup: `system/CHANGE_SHOW_COOKIES_POPUP`,
    }),
    changeAcceptCookies(){
      localStorage.setItem('isAcceptCookies', 'true');
      this.changeShowCookiesPopup(false);
    }
  },
  destroyed() {
  }
}
